import { PlanCode, PricingProductEnum } from "@finq/pricing/types"

export type isSubscribedProps = {
  productIds: PricingProductEnum | PricingProductEnum[]
  planCodes?: PlanCode[]
}

export function useIsSubscribed(props: isSubscribedProps) {
  const login = useLogin()
  const { isAuthenticated, canAccessProduct } = useUser()

  function isSubscribedFn({ productIds, planCodes }: isSubscribedProps) {
    return isAuthenticated.value && canAccessProduct(productIds, planCodes)
  }

  const isSubscribed = computed(() => {
    if (!isAuthenticated.value) {
      return false
    }

    // Call canAccessProduct when authenticated
    return canAccessProduct(props.productIds, props.planCodes)
  })

  function handleVisitorAction({
    callback,
    productIds,
    planCodes,
  }: { callback?: VoidFunction } & Partial<isSubscribedProps>) {
    const sharedArgs = {
      productIds: props.productIds || productIds,
      planCodes: props.planCodes || planCodes || [],
    }

    switch (true) {
      case !isAuthenticated.value:
        return login.open(
          {
            success: () => {
              if (isSubscribedFn(sharedArgs)) return
              callback?.()
            },
          },
          "signup"
        )

      case isAuthenticated.value && !isSubscribedFn(sharedArgs):
        return callback?.()
    }
  }

  return { isSubscribed, isSubscribedFn, handleVisitorAction }
}
